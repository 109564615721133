.arrows {
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease-in-out;
  height: 63px;
  cursor: pointer;
  @media (max-width: 600px) {
    // incase mobile user has search bar at bottom of browser interface
    padding-bottom: 10%;
  }
  svg {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  &:hover {
    padding-bottom: 0;
    svg {
      animation-name: pointDown;
      animation-duration: 1s;
      // animation-iteration-count: infinite;
      // animation-timing-function: ease-in-out;
    }
  }
}

@keyframes pointDown {
  from {
    margin-top: 0;
    opacity: 1;
  }

  to {
    margin-top: 42px;
    opacity: 0;
  }
}

.pulse {
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes pulse {
  from {
    opacity: 100%;
  }

  50% {
    opacity: 20%;
  }

  to {
    opacity: 100%;
  }
}

.hide {
  opacity: 0;
}

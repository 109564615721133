$highlightColor: rgb(242, 196, 10);

@import url("./fonts.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1190px;
  margin: auto;
}

body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  // font-weight: 300;
}

h1,
h2,
h3 {
  font-family: "Tahoma", Arial, Helvetica, sans-serif;
  a {
    text-decoration: none;
  }
}

h1 {
  font-size: 30px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
  font-weight: 100;
}

h4,
h3 {
  color: $highlightColor;
}

p {
  line-height: 1.5;
}

section {
  // min-height: 800px;
  width: 100%;
  padding: 5%;

  h2 {
    font-size: 25px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7%;
    border-bottom: 2px solid;
  }
}

//HEADER

header {
  position: fixed;
  bottom: 0;
  margin: 0;
  z-index: 1;

  width: 100vw;
  height: fit-content;
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: auto;
    border-top: solid 2px;
    // transition: transform 0.03s ease-in; in gloablStyles.js
    box-shadow: rgb(0 0 0 / 15%) 5px 5px 15px;
    a {
      color: #000;
      text-decoration: none;
    }
    h2 {
      font-size: 26px;
      width: fit-content;
      margin: auto;
    }
    // .navContainer {
    //   display: flex;
    // }
  }
}

nav {
  display: none;
  .nav {
    display: inline-block;
    transition: font-weight 0.04s ease-in-out;
    // font-weight: 200;
    &:nth-of-type(n + 2) {
      margin-left: 10px;
    }
  }
}

.nav.active {
  font-weight: 600;
}

header .hideHeader {
  transform: translate(0, 100%);
  box-shadow: 0 0 0 0;
}

.links {
  display: flex;
  height: 30px;
  a {
    display: flex;
    align-items: center;
    &:nth-of-type(n + 2) {
      margin-left: 5px;
    }
    svg {
      fill: #000;
      height: 30px;
      width: 30px;
    }
  }
}

.links a svg,
.projectLinks a,
.links .cv {
  transition: all 0.1s ease-in-out;
}

// .projectLinks a:hover,
.links a svg:hover,
.links .cv:hover {
  transform: scale(0.9);
}

.cv {
  text-decoration: none;
  font-weight: 900;
  font-size: 18px;
  border-radius: 6px;
  transition: transform 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
  transition: color 0.1s ease-in-out;
  .cvIcon {
    height: 100%;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.cvShort .cvIcon {
  padding: 0 4px;
  margin-top: 2px;
}
//INTRO
.intro {
  display: flex;
  align-items: center;
  position: relative;
  height: 100vh;

  h1 {
    margin-bottom: 5px;
    line-height: 1;
  }
  .linkContainer {
    display: flex;
    height: 30px;
    width: fit-content;
    margin: 0;
    margin-left: 2px;
  }
}

.themeToggler {
  height: 30px;
  width: 30px;
  border-radius: 6px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(0.9);
  }
  svg {
    position: absolute;
    width: 24px;
    height: 24px;
    transition: all 1s;
  }
  .themeSun:active {
    opacity: 0;
    transform: rotate(-360deg);
  }

  .themeMoon:active {
    opacity: 0;
    transform: rotate(360deg);
  }
}

// SKILLS
.skills {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.skillsListsContainer {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  gap: 30px;
}

.skillList {
  h3 {
    text-align: center;
    margin-bottom: 2%;
  }

  .skillsListIcons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1%;
    svg {
      width: 48px;
      height: 48px;
      transition: 0.1s;
      &:hover {
        transform: scale(1.05);
        // filter: drop-shadow(0 0 0.5rem $highlightColor);
      }
    }
  }
}

// PROJECTS
.projects {
  position: relative;
  width: 100%;
  // padding-bottom: 50px;
}

.projectContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.project {
  flex: none;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10%;
}

.projectImage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    // height: 100%;
    transition: all 0.1s;
  }

  .phone {
    width: 65%;
  }
  a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.projectInfo {
  text-align: center;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  // p {
  //   margin: 5% 0;
  // }

  h3 {
    margin-bottom: 0.5rem;
  }

  .projectNotes {
    line-height: 1.3;
  }

  .tech {
    font-weight: 100;
    // font-size: 1.05rem;
    // font-size: 0.9rem;
    // margin-bottom: 0.5rem;
    // color: $highlightColor;
  }
}

.projectLinks {
  display: flex;
  justify-content: center;
  gap: 10px;
  a div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  svg {
    width: 35px;
    height: 35px;
  }
  .liveSiteSVG svg {
    width: 30px;
    height: 30px;
  }
}

//ABOUT & CONTACT

.flexContainer {
  display: flex;
  flex-direction: column;
  p {
    text-align: center;
    span {
      font-weight: bold;
    }
  }
}

.aboutImage {
  margin: auto;
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 360px;
    border-radius: 5px;
  }
}

.contactText {
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding-left: 10%;
  padding-right: 10%;
}

#name,
#email,
#message {
  width: 100%;
  padding: 8px;
  font-family: inherit;
  border-radius: 5px;
  // border-style: none;
  // &:focus:not(.inputErrorBorder) {
  //   outline: 2px solid $highlightColor;
  // }
}

.inputErrorBorder {
  outline: solid 1px red;
  &:focus {
    outline: solid 2px red;
  }
}

.submitButton {
  width: 170px;
  margin: auto;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.1s ease;
  &:hover {
    transform: scale(0.9);
  }
}

.joiErrorMsg {
  position: absolute;

  color: red;
  font-size: 12px;
  margin-top: 2px;
}

.msgSentNotification {
  padding: 0;
  p {
    background-color: #24ff02;
    border-radius: 5px;
    margin: auto;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
  }
}

#message {
  max-height: 500px;
}

.about {
  height: 90vh;
  max-height: 1460px;
  min-height: unset;
}

footer {
  max-height: 160px;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 2.5%;
  font-size: 0.8em;
  opacity: 0.7;
}

.Tooltip-Tip::before {
  content: none !important;
}

@media (min-width: 400px) {
  .intro {
    padding: 10%;
  }

  h1 {
    font-size: 36px;
  }

  section h2 {
    font-size: 28px;
  }
}

@media (min-width: 600px) {
  .intro h1 {
    font-size: 44px;
    margin-bottom: 7px;
  }

  // section h2 {
  //   font-size: 28px;
  // }

  p {
    padding: 0 10%;
  }

  .skillList {
    .skillsListIcons {
      // gap: 10px;
      svg {
        width: 64px !important;
        height: 64px !important;
      }
    }
  }
}

@media (min-width: 800px) {
  header {
    top: 0;
    .headerContainer {
      border-bottom: solid 2px;
      border-top: none;
    }
    .hideHeader {
      transform: translate(0, -100%);
    }
  }
  nav {
    display: block;
  }

  .intro {
    padding: 10%;
  }

  .intro h1 {
    font-size: 50px;
    margin-bottom: 8px;
  }

  section h2 {
    font-size: 30px;
    // margin-bottom: 5%;
  }

  p {
    padding: 0;
  }
  // .skillList {
  //   .skillsListIcons {
  //     gap: 20px;
  //     svg {
  //       width: 64px !important;
  //       height: 64px !important;
  //     }
  //   }
  // }

  .project {
    margin-bottom: 7.5%;
    .projectInfo {
      text-align: left;
      .projectLinks {
        justify-content: start;
      }
    }
    &:nth-of-type(2n) .projectInfo {
      order: -1;
      text-align: right;
      .projectLinks {
        justify-content: end;
      }
    }
  }
  .projectInfo,
  .projectImage {
    width: 50%;
  }

  .aboutImage {
    margin: 0;
  }

  .aboutText {
    // remove if you add photo
    margin: auto !important;
    max-width: 650px;
    p {
      // make left align if you add photo
      text-align: center;
    }
  }

  .flexContainer {
    flex-direction: row;

    & > div {
      width: 100%;
    }

    .contactForm,
    .aboutImage {
      margin-left: 5%;
      margin-right: 2.5%;
    }

    .contactText,
    .aboutText {
      margin-right: 5%;
      margin-left: 2.5%;
    }

    .aboutText {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .contactForm {
      order: -1;
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

    .contactText {
      margin-bottom: 5%;
      padding: 0;
    }
  }
}
